import React from 'react';
import john from './john.jpg';
import Button from 'react-bootstrap/Button';

const serverEndpoint = "/bophandler.php"

class App extends React.Component{
  constructor(props){
    super(props)
    this.state={
      bopID:1,
      votes:0,
    }
  }

  componentWillMount(){
    //Calculate calendar boundaries and build calendarData view
    this.refreshVotes()
  }

  makeAPICall(data,errormsg,successfn){
    fetch(serverEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        'Content-Type':'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      if(!response.ok){
        alert(errormsg)
      }
      return response.json()

    })
    .then((responseJson) => {
      if(responseJson.status !== "OK"){
        alert(errormsg)
      }
      console.log(responseJson)
      successfn(responseJson)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  refreshVotes = () => {
    const data = {
      action:"getBops",
      bopID:this.state.bopID
    }
    this.makeAPICall(data,"There was a problem getting Bops",(res)=>{
      console.log(res.votes)
      this.setState({
        votes:res.votes
      })
    })   
  }

  upBop = () => {
    const data = {
      action:"upBop",
      bopID:this.state.bopID
    }
    this.makeAPICall(data,"There was a problem saving your Bop vote",(res)=>{
      console.log("up bop logged") 
      this.refreshVotes() 
    })
  }

  downBop = () => {
    const data = {
      action:"downBop",
      bopID:this.state.bopID
    }
    this.makeAPICall(data,"There was a problem saving your Not vote",(res)=>{
      console.log("down bop logged") 
      this.refreshVotes() 
    })
  }

  render(){
    return (
      <div className="App">
          <div className="bopFrame">
            <img src={john} className="content-image" alt="John" />
            <div className="overlayRow">
              <Button 
                variant = "primary"
                className="bopButton"
                size="lg"
                onClick={()=>{this.upBop()}}
              >Bop</Button>
              <div className="bopResultBox">
                <span className="bopResult">{this.state.votes}</span>
              </div>
              <Button 
                variant="danger"
                className="notButton"
                size="lg"
                onClick={()=>{this.downBop()}}
              >Not</Button>
            </div>
          </div>
      </div>
    );
  }
}

export default App;
